:root {
  --width-sidebar: 250px;
  --width-modal: 320px;
  --width-modal-wide: 360px;

  --width-content: 960px;

  --height-modal: 525px;
  --height-header: 85px;

  --width-card-xs: 360px;
  --width-card-sm: 432px;
  --width-card-md: 480px;
  --width-card-lg: 540px;
  --layout-content-50: calc(
    50% - 0.5rem
  ); /* .5rem is 1/2 the gap between cards within the layout */
}

@custom-media --xs-viewport (max-width: 640px);
@custom-media --small-viewport (max-width: 768px);
@custom-media --medium-viewport (min-width: 769px) and (max-width: 1024px);
@custom-media --large-viewport (min-width: 1025px);
@custom-media --xl-viewport (min-width: 1480px) and (max-width: 1800px);
@custom-media --2xl-viewport (min-width: 1801px);
