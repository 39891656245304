:root {
  --lh-default: 1.5;
  --lh-sm: 1.3;

  --ff-default: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  --fw-light: 300;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-black: 800;

  --fs-xxs: 0.625rem; /* 10px */
  --fs-xs: 0.75rem; /* 12px */
  --fs-sm: 0.875rem; /* 14px */
  --fs-default: 1rem; /* 16px */
  --fs-lg: 1.125rem; /* 18px */
  --fs-xl: 1.25rem; /* 20px */
  --fs-2xl: 1.5rem; /* 24px */
  --fs-3xl: 1.875rem; /* 30px */
  --fs-4xl: 2rem; /* 32px */
}
