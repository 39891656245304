@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(1rem);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideRightFull {
  from {
    left: 0;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes growHeight {
  from {
    max-height: 0px;
  }

  to {
    max-height: 1000px;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

:root {
  --z-header: 2;

  --z-sidebar-backdrop: 3;
  --z-sidebar: 4;

  --z-content: 5;
  --z-above-content: 6;
}

:root {
  --brand-blue-darker: #0075a4;
  --brand-blue-darkest: #035177;
  --brand-green: #09bb9f;
  --brand-yellow: #fdcb04;

  --color-bg-body: #FCFCFC;
  --color-white: rgb(255, 255, 255);
  --color-black: rgb(0, 0, 0);

  --color-grey-darkest: rgb(51, 51, 51);
  --color-grey-darker: rgb(71, 71, 71);
  --color-grey-dark: rgb(92, 92, 92);
  --color-grey-primary: rgb(133, 133, 133);
  --color-grey-light: #ddd;
  --color-grey-lighter: #f2f2f2;
  --color-grey-lightest: rgb(248, 248, 248);

  --color-green-darkest: rgb(12, 97, 45);
  --color-green-darker: rgb(22, 120, 60);
  --color-green-dark: rgb(47, 150, 87);
  --color-green-primary: rgb(75, 181, 116);
  --color-green-light: rgb(133, 228, 170);
  --color-green-lighter: #dafcdb;
  --color-green-lightest: rgb(240, 255, 241);

  --color-yellow-darkest: rgb(132, 108, 35);
  --color-yellow-darker: rgb(183, 153, 61);
  --color-yellow-dark: rgb(219, 184, 77);
  --color-yellow-primary: rgb(255, 216, 96);
  --color-yellow-light: rgb(255, 225, 134);
  --color-yellow-lighter: rgb(255, 238, 187);
  --color-yellow-lightest: rgb(255, 252, 235);

  --color-purple-darkest: rgb(62, 24, 170);
  --color-purple-darker: rgb(87, 64, 180);
  --color-purple-dark: rgb(108, 97, 178);
  --color-purple-primary: rgb(136, 123, 208);
  --color-purple-light: rgb(178, 168, 236);
  --color-purple-lighter: rgb(215, 209, 248);
  --color-purple-lightest: rgb(247, 246, 250);

  --color-blue-darkest: rgb(12, 101, 156);
  --color-blue-darker: rgb(18, 127, 195);
  --color-blue-dark: rgb(15, 144, 225);
  --color-blue-primary: rgb(108, 187, 237);
  --color-blue-light: rgb(158, 215, 248);
  --color-blue-lighter: #e7f4fc;
  --color-blue-lightest: rgb(240, 250, 255);

  --color-orange-darkest: rgb(161, 88, 0);
  --color-orange-darker: rgb(194, 113, 16);
  --color-orange-dark: rgb(227, 136, 27);
  --color-orange-primary: rgb(241, 157, 57);
  --color-orange-light: rgb(255, 190, 113);
  --color-orange-lighter: rgb(255, 218, 174);
  --color-orange-lightest: rgb(255, 244, 232);

  --color-red-darkest: rgb(143, 0, 24);
  --color-red-darker: rgb(181, 36, 60);
  --color-red-dark: rgb(210, 86, 107);
  --color-red-primary: rgb(221, 115, 125);
  --color-red-light: rgb(246, 148, 157);
  --color-red-lighter: rgb(255, 181, 188);
  --color-red-lightest: rgb(255, 227, 230);

  /* Shadow */
  --shadow-xs: 0px 2px 2px var(--color-grey-light);
  --shadow-default: 0px 4px 6px var(--color-grey-light);
}

:root {
  --width-sidebar: 250px;
  --width-modal: 320px;
  --width-modal-wide: 360px;

  --width-content: 960px;

  --height-modal: 525px;
  --height-header: 85px;

  --width-card-xs: 360px;
  --width-card-sm: 432px;
  --width-card-md: 480px;
  --width-card-lg: 540px;
  --layout-content-50: calc(
    50% - 0.5rem
  ); /* .5rem is 1/2 the gap between cards within the layout */
}

:root {
  --lh-default: 1.5;
  --lh-sm: 1.3;

  --ff-default: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  --fw-light: 300;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-black: 800;

  --fs-xxs: 0.625rem; /* 10px */
  --fs-xs: 0.75rem; /* 12px */
  --fs-sm: 0.875rem; /* 14px */
  --fs-default: 1rem; /* 16px */
  --fs-lg: 1.125rem; /* 18px */
  --fs-xl: 1.25rem; /* 20px */
  --fs-2xl: 1.5rem; /* 24px */
  --fs-3xl: 1.875rem; /* 30px */
  --fs-4xl: 2rem; /* 32px */
}

:root {
  --border-radius: 0.5rem;
  --round-border: 9999px;
}

:root {
  --lh-default: 1.5;
  --lh-sm: 1.3;

  --ff-default: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  --fw-light: 300;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-black: 800;

  --fs-xxs: 0.625rem; /* 10px */
  --fs-xs: 0.75rem; /* 12px */
  --fs-sm: 0.875rem; /* 14px */
  --fs-default: 1rem; /* 16px */
  --fs-lg: 1.125rem; /* 18px */
  --fs-xl: 1.25rem; /* 20px */
  --fs-2xl: 1.5rem; /* 24px */
  --fs-3xl: 1.875rem; /* 30px */
  --fs-4xl: 2rem; /* 32px */
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: var(--ff-default);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--ff-default);
  line-height: var(--lh-default);
  font-size: var(--fs-default);
  background: var(--color-grey-lightest);
  color: var(--color-grey-darkest);
}

html,
body,
#__next,
main {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: var(--fw-semibold);
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

@media (max-width: 768px) {

h4 {
    font-size: var(--fs-sm)
}
  }

a {
  color: var(--color-blue-darker);
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
    color: var(--color-blue-darkest);
  }

img {
  max-width: 100%;
  display: block;
}

b {
  font-weight: var(--fw-semibold);
}

button {
  font-size: var(--fs-default);
}

/* For tooltip-enabled element */
[data-mui-internal-clone-element] {
  -webkit-text-decoration: 1px underline dashed currentColor;
          text-decoration: 1px underline dashed currentColor;
}

kbd {
  font-family: monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_a64ecd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_a64ecd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_a64ecd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_a64ecd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_a64ecd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_a64ecd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_a64ecd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_a64ecd';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_a64ecd {font-family: '__Inter_a64ecd', '__Inter_Fallback_a64ecd';font-style: normal
}

