@import './variables/animations.css';
@import './variables/z-indices.css';
@import './variables/colors.css';
@import './variables/dimensions.css';
@import './variables/fonts.css';
@import './variables/borders.css';
@import './variables/fonts.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: var(--ff-default);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--ff-default);
  line-height: var(--lh-default);
  font-size: var(--fs-default);
  background: var(--color-grey-lightest);
  color: var(--color-grey-darkest);
}

html,
body,
#__next,
main {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: var(--fw-semibold);
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
  @media --small-viewport {
    font-size: var(--fs-sm);
  }
}

a {
  color: var(--color-blue-darker);
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: var(--color-blue-darkest);
  }
}

img {
  max-width: 100%;
  display: block;
}

b {
  font-weight: var(--fw-semibold);
}

button {
  font-size: var(--fs-default);
}

/* For tooltip-enabled element */
[data-mui-internal-clone-element] {
  text-decoration: 1px underline dashed currentColor;
}

kbd {
  font-family: monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
